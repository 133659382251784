import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SingleSlide from "../Global/SingleSlide";
import xicon from "../../../assets/images/xicon.png";
import maskman from "../../../assets/images/maskman.png";
import tina from "../../../assets/images/tina.png";
import slayhem from "../../../assets/images/slayhem.jpeg";
import crypteanux from "../../../assets/images/crypteanux.jpeg";
import mike from "../../../assets/images/mike.jpeg";
import sup from "../../../assets/images/sup.jpg";
import cal from "../../../assets/images/cal.jpg";

const slides = [
  {
    image: maskman,
    name: "SHIBTOSHI",
    title: "Chief Executive Officer",
    description:
      "Shibtoshi is a prominent figure in the cryptocurrency world, recognized as one of the top Shiba Inu whales and a Bitcoin holder since 2011. With extensive experience and influence in the crypto space, he drives innovative blockchain solutions and advancements.",
   // icon: xicon,
  },
  {
    image: tina,
    name: "Tina Nemat",
    title: "Chief Marketing Officer",
    description:
      "Tina Nemat is a strategic marketing leader with a strong background in finance and a proven track record of guiding startups to success across various industries. She specializes in driving impactful partnerships and building networks to propel project growth.",
    //icon: xicon,
  },
  {
    image: crypteanux,
    name: "Crypteaux Breaux",
    title: "CDO",
    description:
      "Crypteaux brings over 20 years of experience in financial services and data analytics, with executive leadership in managing multibillion-dollar portfolios. A crypto investor since 2014, he leads portfolio analysis, holder analytics, and blockchain forensics for SquidGrow.",
    //icon: xicon,
  },
  {
    image: slayhem,
    name: "Slayhem",
    title: "CIO",
    description:
      "Slayhem is an enigmatic programmer known for his extraordinary abilities and unconventional approach to coding. His unique methodology and mysterious persona have made him a captivating figure in the development world, consistently pushing the boundaries of what's possible in software engineering.",
    icon: xicon,
  },
  {
    image: mike,
    name: "Mike",
    title: "Chief Creative Officer",
    description:
      "Mike is a 31 year veteran of the comic book industry, artist of DC Comics INJUSTICE: GODS AMONG US, the #1 digital comic in the world...",
    icon: xicon,
  },
  {
    image: sup,
    name: "Supdoggie",
    title: "Lead Developer of SilentSwap",
    description:
      "Supdoggie is a seasoned blockchain engineer with a deep focus on privacy technologies, shaping SilentSwap’s cutting-edge privacy solutions.",
    icon: xicon,
  },
    {
    image: cal,
    name: "Cal",
    title: "Lead Developer of Squidgrow",
    description:
      "Cal is a seasoned blockchain engineer with a deep focus on decentralized app and Smart Contract development.",
    icon: xicon,
  },
  // {
  //   image: maskman,
  //   name: "Crypteaux Breaux",
  //   title: "Chief Data Officer",
  //   description:
  //     "Crypteaux Breaux brings over 20 years of expertise in software development, financial services, and data analytics. His executive leadership includes overseeing multimillion-dollar acquisitions and managing a multibillion-dollar portfolio.",
  //   icon: xicon,
  // },
  // {
  //   image: tina,
  //   name: "Mike Miller",
  //   title: "Chief Creative Officer",
  //   description:
  //     "Mike is a Marvel/DC comic book creator of over three decades, artist of the #1 digital comic in the world 5 years running: Injustice Gods Among Us (DC Comics) and believes the future of media and entertainment will be inextricably woven into blockchain technology.",
  //   icon: xicon,
  // },
  // {
  //   image: tina,
  //   name: "Cal",
  //   title: "Lead Developer of SquidGrow",
  //   description:
  //     "Cal is a blockchain architect specializing in decentralized systems and secure DeFi solutions. His technical expertise drives the development of SquidGrow’s innovative ecosystem.",
  //   icon: xicon,
  // },
  // {
  //   image: tina,
  //   name: "Supdoggie",
  //   title: "Lead Developer of SilentSwap",
  //   description:
  //     "Supdoggie is a seasoned blockchain engineer with a deep focus on privacy and decentralized technologies, shaping SilentSwap’s cutting-edge privacy solutions.",
  //   icon: xicon,
  // },
];

function SlideSection() {
  const [distance, setDistance] = useState(0);
  const [width, setWidth] = useState("100%");

  const updateDimensions = () => {
    const heroContent = document.querySelector(".hero-content");
    if (heroContent) {
      const rect = heroContent.getBoundingClientRect();
      const distanceFromLeft = rect.left;
      setDistance(distanceFromLeft);
      setWidth(`calc(100% - ${distanceFromLeft}px)`); // Corrected line
    }
  };

  // Function to set equal height for all slides
  const setEqualHeight = () => {
    const items = document.querySelectorAll(".owl-item .item");
    let maxHeight = 0;

    // Reset heights first
    items.forEach((item) => {
      item.style.height = "auto";
    });

    // Find max height
    items.forEach((item) => {
      const itemHeight = item.offsetHeight;
      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }
    });

    // Apply the max height to all items
    items.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    // Apply equal height after the component mounts or after the window resize
    setEqualHeight();
    window.addEventListener("resize", setEqualHeight);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("resize", setEqualHeight);
    };
  }, []);

  return (
    <section className="slide-section">
      <div className="slide-wrapper" style={{ width: width }}>
        <div className="slide-left">
          <h1>Our team is a diverse powerhouse of blockchain veterans</h1>
        </div>
        <div className="slide-right">
          <OwlCarousel
            className="owl-theme"
            loop
            responsiveClass
            autoplay
            dots={false}
            nav={false}
            responsive={{
              0: {
                margin: 32,
                items: 1.2,
                dots: true,
              },
              768: {
                margin: 66,
                items: 1.3,
              },
            }}
          >
            {slides.map((slide, index) => (
              <div className="item" key={index}>
                <SingleSlide
                  image={slide.image}
                  name={slide.name}
                  title={slide.title}
                  description={slide.description}
                  icon={slide.icon}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default SlideSection;
