import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/sections/Global/Footer";
import BlogBox from "../components/sections/Global/BlogBox";
import cryptonews from "../assets/images/cryptonews.png";
import blockster from "../assets/images/blockster.png";
import sgeco from "../assets/images/sgeco.jpg";

function Blogs() {
  const blogs = [
    {
      slug: "squidgrow-vision-to-reality",
      title: "SquidGrow: From Vision to Reality",
      image: sgeco,
      headline: "The Journey of SquidGrow's Development",
      description:
        "From high-stakes poker to Bitcoin revelations: The extraordinary origin story of a crypto project that refuses to be just another meme coin.",
    },
    {
      slug: "liquidity-locking-secures-trust",
      title: "How Liquidity Locking Secures Trust in Crypto Projects",
      image: cryptonews,
      headline: "Why Liquidity Locking is Essential",
      description:
        "Find out how liquidity lockers protect investors from scams like rug pulls.",
    },
    {
      slug: "dont-let-scams-steal-memecoins",
      title: "Don’t Let Scams Steal Your Memecoins – Use SquidGrow",
      image: blockster,
      headline: "Protect Your Memecoins with SquidGrow",
      description:
        "Learn how SquidGrow helps you safeguard your investments in memecoins.",
    },
 
  ];
  return (
    <>
      <header className="header">
        <Navbar />
      </header>
      <main>
        <h1 className="maintitle"> Blogs</h1>
        <section className="press-section">
          {blogs.map((blog) => (
            <BlogBox key={blog.slug} {...blog} />
          ))}
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blogs;
