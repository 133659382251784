import React from "react";
import partner1 from "../../../assets/images/p1.jpeg"
import partner2 from "../../../assets/images/p2.jpeg"
import partner3 from "../../../assets/images/p3.jpeg"
import partner4 from "../../../assets/images/p4.jpeg"
import partner5 from "../../../assets/images/p5.jpeg"
function PartnerSection() {
  return (
    <section className="ambassador-section">
      <h1>Partners</h1>
      <div className="ambassador-row partner-row">
<img src={partner1}/>
<img src={partner2}/>
<img src={partner3}/>
<img src={partner4}/>
<img src={partner5}/>
    
      </div>
    </section>
  );
}

export default PartnerSection;
