import React from "react";
import { Link } from "react-router-dom";

function BlogBox({ title, image, headline, description, slug }) {
  return (
    <div className="single-press">
      <h5>{title}</h5>
      <img src={image} alt={title} />
      <h3>{headline}</h3>
      <p>{description}</p>
      <Link to={`/blog/${slug}`} className="purplebtn purplehover">
        Read More
      </Link>
    </div>
  );
}

export default BlogBox;
